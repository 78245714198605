    .products-container {
        .products-header {
            border-bottom: 1px solid $main-black;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            h1 {
                text-align: left;
                margin: 20px 0 0 0;
                line-height: 1.2;
                font-size: 1.5em;
                // font-weight: bold;
                font-family: Arial, Helvetica, sans-serif;
                padding-bottom: 10px;
                color: $main-black;
            }
            img {
                height: 40px;
                margin-left: 10px;
            }
        }
        .item {
            display: flex;
            flex: row wrap;
            max-height: 400px;
            margin: 20px 10px;
            box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
            transition: all 500ms ease-in-out;
            justify-content: space-between;

            @media (max-width: 1200px) {
                max-height: 100%;
            }

            @media (max-width: 768px) {
                max-height: 100%;
            }
            .left {
                display: flex;
                align-items: baseline;
                flex-flow: column;
                text-align: left;
                margin: 15px 0px 0px 20px;
                @media (max-width: 768px) {
                    text-align: center;
                    margin: 0;
                }
                .title {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    margin-left: 30px;
                    
                    @media (max-width: 768px) {
                        margin: 10px 0 0 5px;
                        ;
                    }
                    h1 {
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: 1.5rem;
                        font-weight: bold;
                        margin-left: 5px;
                        margin-top: 5px;
                        transition: 1;
                        &:hover {
                            text-decoration: underline;
                            cursor: pointer;
                        }

                        @media (max-width: 768px) {
                            margin: 10px 0 0 10px;
                        }
                    }
                    img {
                        height: 40px;
                    }
                }
                p {
                    margin-left: 40px;
                    margin-top: 10px;
                    font-family: $main-font;
                    width: 90%;
                    font-size: 16px;
                    line-height: 1.5;
                    
    
                    @media (max-width: 768px) {
                        margin: 10px 0 0 10px;
                    }
                }

                .stack{
                    font-weight: bold;
                    margin: 10px 0 10px 40px;
                    @media (max-width: 768px) {
                        margin: 10px 0 0 10px;
                    }
                }
            }
            .right {
                overflow: hidden;
                width: 30%;
                height: inherit;
            

                    @media (max-width: 1024px) {
                        display: none;
                    }
                
            }
            // &:hover {
            //     box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
            //     transition: all 500ms ease-in-out;
            //     background: rgba(123, 164, 226, 0.1);
            //     width: 100%;
            //     margin: 20px 0px;
            //     cursor: pointer;
            // }
        }
    }

    .link-hover{
        font-weight: normal;
        width: 300px;
        position: relative;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-transition: color 1000ms;
            transition: color 1000ms;
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #5393fe;
            -webkit-transform: scaleY(0);
            transform: scaleY(0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition: 300ms ease-out;
            transition: 300ms ease-out;
        }

        &:hover:before {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }

        &:hover{
            color:white;
            font-weight: bolder;
            padding:0 10px;
            cursor:pointer;
        }
    }