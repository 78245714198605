nav {
    height: 50px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid $main-black;
    // @media (max-width: 768px) {
    //     flex-flow: column nowrap;
    //     align-items: center;
    //     border-bottom: none;
    //     justify-content: flex-end;
    // }
    @media (max-width: 768px) {
        height: 50%;
    }
    .logo {
        img {
            height: 50px;
            padding-bottom: 10px;
            @media (max-width: 768px) {
                display: none;
            }
        }
    }
    a {
        font-weight: 600;
        font-size: 0.95em;
        text-decoration: none;
        margin: 0px 20px 0 5px;
        color: $main-black;
        padding-bottom: 10px;
        transition: 500ms;
        font-family: $main-font;
        &:hover {
            font-size: 1.4rem;
            font-weight: bold;
        }
    }
    .links {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
        // @media (max-width: 768px) {
        //     flex-flow: column;
        //     align-items: flex-start;
        // }
        @media (max-width: 768px) {
            justify-content: center;
            margin: 10px auto;
        }
        .social-icon {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
        }
    }
}