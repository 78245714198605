.skills{
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    max-width: 90%;
    margin:20px auto;

    li{
        margin: 10px;
        padding: 10px;
        font-family: $main-font;
        background: white;
        border: 1px solid gray;
        border-radius: 10px;
    }
}