footer {
    position: relative;
    bottom: -25px;
    font-size: 12px;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 13px;
}