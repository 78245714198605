.App {
    margin: 20px 200px;

        @media (max-width: 1440px) {
            margin: 20px 100px;
        }
    @media (max-width: 768px) {
        margin: 20px 100px;
    }
    @media (max-width: 495px) {
        margin: 20px auto;
    }
}